import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ConfirmService, DataService } from '../services/index'
import { Error } from '../models/index'
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-hotels-redirect',
  templateUrl: './hotels-redirect.component.html',
  styleUrls: ['./hotels-redirect.component.css']
})
export class HotelsRedirectComponent implements OnInit {
  sub:any;
  confirmData:any;
  res:any;
  isError:boolean;
  errorMessage:any;
  successMessage:any;
  isWaiting:boolean;
  waitingMessage:any;
  api_error:Error;

  constructor(
     @Inject(DOCUMENT) private document: Document,
      private router: Router,
      private route: ActivatedRoute,
      private service: ConfirmService,
      private dataService: DataService) { }
      
      // 7914320
      ngOnInit(): void {
        setTimeout(()=>{
             this.isWaiting=false;
             this.goToLink('https://www.booking.com/index.html?aid=7973338'  )
           }, 3000)
      }

      goToLink(url: string): void {
        this.isWaiting=false;
        const link = this.document.createElement('a');
        link.href = url;
        link.click();
        link.remove();
      }
}
