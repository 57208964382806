import { Component, OnInit } from '@angular/core';
import {style, animate, transition, trigger, group} from '@angular/animations';
import { SharedscrollstatusService, MixpanelService } from 'src/app/services/index';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service'
import { User } from '../../models/index'
import { Error } from '../../models/index'
import { ConfirmedValidator } from './confirm-validator';
@Component({
  selector: 'app-headerhome',
  templateUrl: './headerhome.component.html',
  styleUrls: ['./headerhome.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity:0,
          transform: 'translateX(-100%)'
        }),
        group([
          animate(500, style({opacity:1})),
          animate('500ms ease-in', style({transform: 'translateX(0%)'}))
        ])

      ]),
      transition(':leave', [
        group([
          animate(500, style({opacity:0})),
          animate('500ms ease-in', style({transform: 'translateX(-100%)'}))
        ])

      ])
    ])
  ]
})
export class HeaderhomeComponent implements OnInit {
  public toggle:boolean;
    signupForm: UntypedFormGroup;
    startForm: UntypedFormGroup;
    loginForm: UntypedFormGroup;
    resetForm: UntypedFormGroup;
    codeForm: UntypedFormGroup;
    passwordUpdateForm: UntypedFormGroup;
    submitted=false;
    loginsubmitted=false;
    is_loggedin=false;
    success:boolean=false;
    successLogin:boolean=false;
    loadingStart:boolean = false;
    loadingSignup:boolean = false;
    loadingLogin:boolean = false;
    resetsubmitted:boolean = false;
    loadingReset:boolean = false;
    successReset:boolean = false;
    codeubmitted:boolean=false;
    loadingCode:boolean=false;
    updateSubmitted:boolean = false;
    loadingPasswordUpdate:boolean = false;
    loadingUpdatePassword:boolean = false;
    successPasswordUpdate:boolean = false;
    updatePassSuccessMsg: string;
    isHidden:boolean = true;
    successode:boolean = true;
    startEmail:any;
    resetErrMsg:any;
    resetSuccessMsg:any;
    codeSuccessMsg:string;
    passToken:string;
    resMsg:any;
    res:any;
    resLogin:any;
    isLoggedIn:any;
    user:User;
    api_error:Error;
    fieldTextType: boolean;
    api_error_login:any;
    user_email_reset:string;
    constructor(
      private service:SharedscrollstatusService,
      private _fb: UntypedFormBuilder,
      private mixpanelService: MixpanelService,
      private users:UserService) {
    this.service.currentValue.subscribe(message => this.toggle = message);//subscribe to the currentValue observable.
    this.signupForm = _fb.group({
      first_name:['', Validators.required],
      last_name:['', Validators.required],
      phone_number:['', [Validators.required,Validators.pattern('^(?:254|\\+254|0)(7|1)[0-9]{8}$')]],
      id_number:['',[ Validators.required,Validators.pattern('^[a-zA-Z0-9]*$'),Validators.minLength(7),Validators.maxLength(14)]],
      email:['', [Validators.required,Validators.email]],
      password:['', [Validators.required,Validators.minLength(6)]],
    });

    this.codeForm = _fb.group({
      code:['', Validators.required],
    });
    this.passwordUpdateForm = _fb.group({
      password:['', [Validators.required,Validators.minLength(8)]],
      confirmpassword:['', [Validators.required,Validators.minLength(8)]],
    },{ 
      validator: ConfirmedValidator('password', 'confirmpassword')
    });

    this.startForm = _fb.group({
      email:['', [Validators.required,Validators.email]],
    });
    this.resetForm = _fb.group({
      email:['', [Validators.required,Validators.email]],
    });
    this.loginForm = _fb.group({
      password:['', Validators.required],
    });
  }

  ngOnInit() {
    this.isLoggedIn = localStorage.getItem('isLoggedIn');
    if(this.isLoggedIn ){
      this.user=new User().deserialize(JSON.parse(localStorage.getItem('user')))
      this.getUserProfile(this.user);
    }
  }
  logoutUser(){
   localStorage.removeItem("user");
    this.isLoggedIn=false;
  }
  getUserProfile(user:any){
    this.users.getProfile(user).subscribe(
      response => {
          this.loadingStart=false;
          this.resMsg=response;
          localStorage.setItem('user',JSON.stringify(this.resMsg.data));
      },
      error => {
       localStorage.removeItem("user");
        this.isLoggedIn=false;
      }
    );
  }
submitStart = () => {
  this.loadingStart=true;
  this.loginsubmitted=true;
  if (this.startForm.invalid ) {
      this.loadingStart=false;
      return;
  }
  let user_email=this.startEmail= this.startForm.value.email;
  this.users.checkEmail(user_email).subscribe(
    response => {
        this.loadingStart=false;
        document.getElementById("closeStart").click();
        document.getElementById("openModalButton").click();

    },
    error => {
      this.loadingStart=false;
      this.api_error=new Error().deserialize(error.error.errors)
      if(!error.error.status){
        this.signupForm.patchValue({email:user_email });
        document.getElementById("closeStart").click();
        document.getElementById("openSignupButton").click();
      }
    }
  );
}
backToEmail= () => {
      this.loginsubmitted=false
      document.getElementById("openModalStart").click();
      document.getElementById("closePassword").click();
}
initiateResetPassword= () => {
      document.getElementById("closePassword").click();
      document.getElementById("openModalReset").click();
}
toggleFieldTextType= () => {
  this.fieldTextType = !this.fieldTextType;
}
 get f() { return this.signupForm.controls; }

submitSignUp = () => {
    this.submitted = true;
    this.loadingSignup=true;
    // stop here if form is invalid
    if (this.signupForm.invalid ) {
      this.submitted = false;
      this.loadingSignup=false;
        return;
    }
    let value = this.signupForm.value;
    let webformdata={
      "first_name": value.first_name,
      "last_name": value.last_name,
      "email": value.email,
      "password": value.password,
      "id_number": value.id_number,
      "phone_number": '+254'+ value.phone_number.substring(1)
    }

    const mixSignupData = {
      name: value.first_name + ' ' + value.last_name,
      email: value.email,
      utmsource: 'buupassSite',
      utmmedium:  'web',
      source: 'buupassSite',
      role: 'customer',
    }

    // console.log(mixSignupData);

    this.mixpanelService.track('Signup', mixSignupData);

    this.users.signUp(webformdata).subscribe(
      response => {
        this.loadingSignup=false;
          this.success = true;
          this.resMsg=response;
          this.res= this.resMsg.data.message;
          document.getElementById("closeSignup").click();
          document.getElementById("openSuccessButton").click();
      },
      error => {
          this.success = false;
          this.loadingSignup=false;
          this.user=new User().deserialize(error.error.errors[0])
      }
    );
}

submitLogin = () => {
      this.loginsubmitted=true;
      this.loadingLogin=true;
     if (this.loginForm.invalid ) {
      this.loginsubmitted=false;
      this.loadingLogin=false;
        return;
     }
    let value = this.loginForm.value;
    let webformlogin={
      "password": value.password,
      'email':this.startEmail,
    }

    const mixLoginData = {
      email: value.email,
      utmsource: 'buupassSite',
      utmmedium:  'web',
      source: 'buupassSite',
      role: 'customer',
    }

     this.mixpanelService.track('Login', mixLoginData);
    this.users.login(webformlogin).subscribe(
      response => {
          this.loadingLogin=false;
          this.loginsubmitted=false;
          this.successLogin = true;
          this.resMsg=response;
          if(this.resMsg.data){
            localStorage.setItem('isLoggedIn','true');
            localStorage.setItem('user',JSON.stringify(this.resMsg.data));
            this.user=new User().deserialize(JSON.parse(localStorage.getItem('user')))
            this.isLoggedIn=true;
            document.getElementById("closePassword").click();
            
          }
      },
      error => {
          this.successLogin = false;
          this.loginsubmitted=false;
          this.loadingLogin=false;
          
          this.api_error=new Error().deserialize(error.error.errors[0])
          this.api_error_login=this.api_error[0];
      }
    );
}

submitResetPassword = () =>{
  this.resetsubmitted=true;
  this.loadingReset=true;
 if (this.resetForm.invalid ) {
  this.resetsubmitted=false;
  this.loadingReset=false;
    return;
 }
let value = this.resetForm.value;
let webformReset={
  "email": value.email
}
this.users.passwordReset(webformReset).subscribe(
  response => {
      this.loadingReset=false;
      this.resetsubmitted=false;
      this.successReset = true;
      this.resMsg=response;
      this.resetSuccessMsg=this.resMsg.data.message;
      this.user_email_reset=value.email;
      document.getElementById("openCodeButton").click();
      document.getElementById("closeReset").click();
  },
  error => {
      this.successReset = false;
      this.resetsubmitted=false;
      this.loadingReset=false;
      this.api_error= new Error().deserialize(error.error.errors);
      this.resetErrMsg=this.api_error['0'];
  }
);
}

// send reset code to verify
submitCode= () =>{
  this.codeubmitted=true;
  this.loadingCode=true;
 if (this.codeForm.invalid ) {
  this.codeubmitted=false;
  this.loadingCode=false;
    return;
 }
let value = this.codeForm.value;

let webformCode={
  "email": this.user_email_reset,
  "otp": value.code
}

this.users.sendCode(webformCode).subscribe(
  response => {
      this.loadingCode=false;
      this.codeubmitted=false;
      this.successode = true;
      this.resMsg=response;
      this.codeSuccessMsg=this.resMsg.data.message;
      this.passToken=this.resMsg.data.token;
      document.getElementById("closeCode").click();
      document.getElementById("openUpdatePassword").click();
  },
  error => {
      this.successode = false;
      this.codeubmitted=false;
      this.loadingCode=false;
      this.api_error= new Error().deserialize(error.error.errors);
      this.codeSuccessMsg=this.api_error['0'];
  }
);
}
// send reset code to verify
updatePassword= () =>{
  this.updateSubmitted=true;
  this.loadingPasswordUpdate=true;
 if (this.passwordUpdateForm.invalid ) {
    this.updateSubmitted=false;
    this.loadingPasswordUpdate=false;
    return;
 }
let value = this.passwordUpdateForm.value;

let webformCode={
  "email": this.user_email_reset,
  "new_password": value.password,
  "token": this.passToken
}
this.users.sendUpdatePassword(webformCode).subscribe(
  response => {
      this.loadingUpdatePassword=false;
      this.updateSubmitted=false;
      this.successPasswordUpdate = true;
      this.resMsg=response;
      this.updatePassSuccessMsg=this.resMsg.data.message;
      document.getElementById("closePasswordUpdate").click();
      document.getElementById("openResetSuccessButton").click();
  },
  error => {
      this.successPasswordUpdate = false;
      this.updateSubmitted=false;
      this.loadingPasswordUpdate=false;
      this.api_error= new Error().deserialize(error.error.errors);
      this.updatePassSuccessMsg=this.api_error['0'];
  }
);
}



}
