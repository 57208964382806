import { Component, OnInit, Input, Output, EventEmitter, HostListener, forwardRef } from '@angular/core';
import { AbstractControl,ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {Observable,interval } from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import { DropdownStatusService } from '../../services/dropdown-status.service';
import { FromStatusService } from 'src/app/services/from-status.service';
import { PassDestinationValueService } from 'src/app/services/pass-destination-value.service';
const noop = () => {
};

let topCities =[
  "Nairobi",
 "Kisumu",
 "Busia",
 "Sirare",
 "Mumias",
 "Malaba",
 "Usenge",
]
export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DropdownComponent),
  multi: true
};

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class DropdownComponent implements OnInit, ControlValueAccessor {

  @Input() dropdownoptions: Array<string>;
  @Input() selected: number;
  @Input() className: string;
  @Input() placeholder: string;
  @Input() isReadOnly = false;
  @Output() optSelect = new EventEmitter();
  isOpen = false;
  selectedOption;
  location:string;
  control: AbstractControl | null;
  isSelected:boolean;
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  isSelectedValue: boolean;
  key: string;
  isFocused: boolean;
  dataOptions:any;
  dataOptionsValues=[];
  timer;
  oldValue;

  constructor(private service: DropdownStatusService,
              private fromService:FromStatusService,
              private destValue: PassDestinationValueService){

                this.destValue.destinationValue.subscribe(message => {
                  if(message)
                    this.setValue(message);
                    this.onChangeCallback(message);
                    this.optSelect.emit(message);
                });
                this.fromService.statusValue.subscribe(message => {
                  if(message) this.isOpen = false
                });
                this.fromService.statusDateValue.subscribe(message => {
                  if(message) this.isOpen = false
                });
                this.fromService.valueChange.subscribe(message => {
                  this.dataOptionsValues=[];
                  if(typeof this.dropdownoptions !== 'undefined' && this.dropdownoptions.length > 0){
                    for( var i = 0; i < this.dropdownoptions.length; i++){
                      if ( this.dropdownoptions[i] !== message) this.dataOptionsValues.push(this.dropdownoptions[i])
                   }
                  }
                  this.dataOptions=this.dataOptionsValues
                });
              }
  /**
   *Creates an instance of DropdownComponent.
   * @memberof DropdownComponent
   */
  ngOnInit() {
    // Place default value in dropdown
    if (this.selected) {
      this.placeholder = '';
      this.isOpen = false;
    }
    this.timer=interval(100).subscribe(x => { // will execute every 30 seconds
      this.assignValue();
    });
  }
  assignValue(){
    if(typeof this.dropdownoptions !== 'undefined' && this.dropdownoptions.length > 0){
      this.dataOptions =[...topCities, ...this.dropdownoptions];
    
      this.dataOptions = [...new Set(this.dataOptions)];      
      this.dropdownoptions=this.dataOptions;
      this.timer.unsubscribe();
      // this.dataOptions=this.dropdownoptions;
      // this.timer.unsubscribe();
    }
  }
  get value(): Array<String> {
    return this.dropdownoptions;
  }
  @HostListener('focus')
  focusHandler() {
    this.selected = 0;
    this.isFocused = true;
  }
  setValue(location:string) { this.location = location; }
  @HostListener('focusout')
  focusOutHandler() {
    this.isFocused = false;
  }
  @HostListener('document:keydown', ['$event'])
  keyPressHandle(event: KeyboardEvent) {
    if (this.isFocused) {
      this.key = event.code;
      switch (this.key) {
        case 'Space':
          this.isOpen = true;
          break;
        case 'ArrowDown':
          if (this.dropdownoptions.length - 1 > this.selected) {
            this.selected = this.selected + 1;
          }
          break;
        case 'ArrowUp':
          if (this.selected > 0) {
            this.selected = this.selected - 1;
          }
          break;
        case 'Enter':
          if (this.selected > 0) {
            this.isSelectedValue = true;

            this.isOpen = false;
            this.onChangeCallback(this.selected);
            this.optSelect.emit(this.dropdownoptions[this.selected]);
          }
          break;
      }
    }

  }

  /**
  * option selection
  * @param {string} selectedOption - text
  * @param {number} idx - current index of item
  * @param {any} event - object
  */
  optionSelect(selectedOption: string, idx, e: any) {
    e.stopPropagation();
    this.selected = idx;
    this.setValue(this.dataOptionsValues[this.selected]);
    this.isSelectedValue = true;
    this.isOpen = false;
    this.onChangeCallback(selectedOption);
    this.optSelect.emit(selectedOption);
  }

  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term =>
      {
        term.length < 1 && this.dataOptionsValues.length > 0
      ?  this.dataOptionsValues =this.dataOptions
      : this.dataOptionsValues=this.dataOptionsValues.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)

      })
  )
  /**
  * toggle the dropdown
  * @param {any} event object
  */
  toggle(e: any) {
    e.stopPropagation();
    this.setValue('');
    this.dataOptionsValues =this.dataOptions
    const allElems = document.querySelectorAll('.dropdown-wrapper');
    for (let i = 0; i < allElems.length; i++) {
      allElems[i].classList.remove('is-open');
    }
    this.isOpen = !this.isOpen;
    this.service.changeValue(this.isOpen);

  }

  checkIfOpen(e: any){
    this.setValue('');
    this.dataOptionsValues =this.dataOptions
    const allElems = document.querySelectorAll('.dropdown-wrapper');
    for (let i = 0; i < allElems.length; i++) {
      allElems[i].classList.remove('is-open');
    }
    this.isOpen=true;
  }
  /**
  * dropdown click on outside
  */
  @HostListener('document: click', ['$event'])
  onClick() {
    this.isOpen = false;
  }
  /**
   * Method implemented from ControlValueAccessor and set default selected value
   * @param {*} obj
   * @memberof DropdownComponent
   */
  writeValue(obj: any): void {

    if (obj && obj !== '') {
      this.isSelectedValue = true;
      this.selected = obj;

    } else {
      this.isSelectedValue = false;
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {

  }

}
